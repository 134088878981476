<template>
  <div v-if="Object.keys(league).length">
    <DashboardHero
      :title="league.title[language]"
      class="mt-2 mb-5"
      type="cover"
      size="xs"
      :background="leaguesLocalPerType[league.type].background"
      :tag="$t('league')"
    >
      <template #footer>
        <div class="text-sm opacity-70">{{ league.target[language] }}</div>
      </template>
    </DashboardHero>

    <fw-panel title="Estatísticas" class="my-5">
      <PanelStats :stats="editionStats" :stats-warning="false" />
    </fw-panel>

    <fw-panel v-if="league.sports" title="Modalidades" class="my-5">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-5">
        <div
          v-for="sport in league.sports"
          :key="sport.key + '-' + edition.key"
          class="group bg-gray-200 flex-col flex p-5 rounded-xl"
        >
          <div class="flex-1 flex flex-col justify-end gap-2">
            <div class="font-bold uppercase flex-1">
              <v-clamp autoresize :max-lines="3">
                {{ sport.modality.title[language] }} {{ sport.category[language] }}
              </v-clamp>
            </div>
            <div v-if="sport.stats" class="flex flex-col gap-0.5 font-semibold text-gray-500 text-sm">
              <div>
                {{ sport.stats.total_tournaments ?? 0 }} {{ $tc('tournaments', sport.stats.total_tournaments ?? 0) }}
              </div>
              <div>
                {{ sport.stats.approved_teams ?? 0 }} {{ $tc('teams', sport.stats.approved_teams ?? 0) }}
                <span class="text-xs opacity-70">(máx. {{ sport.max_teams }})</span>
              </div>

              <div>{{ sport.stats.total_players ?? 0 }} {{ $tc('players', sport.stats.total_players ?? 0) }}</div>
            </div>
          </div>
        </div>
      </div>
    </fw-panel>

    <!-- <fw-panel title="Gestores" class="my-5" boxed="sm" custom-class="flex gap-5 bg-white">
      <div v-if="users" class="grid grid-cols-3 gap-1 w-full">
        <Person v-for="userManager in users" :key="userManager.key" :no-style="true" :person="userManager" />
      </div>
      <div v-else class="text-sm text-gray-500">
        Ainda não foram associados gestores a esta edição.
      </div>
    </fw-panel> -->

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ league, leagues, edition }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
// import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import { LEAGUES_PER_TYPE_AS_ARRAY, LEAGUES_PER_TYPE } from '@/utils/index.js'

export default {
  components: {
    DashboardHero,
    // Person,
    PanelStats
  },

  props: {
    edition: {
      type: Object,
      default: () => {}
    },
    league: {
      type: Object,
      default: () => {}
    },
    leagues: {
      type: Array,
      default: () => {}
    },
    checks: {
      type: Object,
      default: () => {}
    },
    leagueKey: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      leaguesLocalPerType: LEAGUES_PER_TYPE,
      leaguesLocalPerTypeArray: LEAGUES_PER_TYPE_AS_ARRAY
    }
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },
    editionStats() {
      if (!this.league.stats) return
      return [
        {
          label: this.$tc('publishedTournaments', 0),
          value: this.league.stats.total_tournaments ?? 0,
          valueSuffix: null,
          description: null
        },
        {
          label: 'Equipas aprovadas',
          value: this.league.stats.approved_teams ?? 0,
          valueSuffix: null,
          description: null
        },
        {
          label: 'Equipas por aprovar',
          value: this.league.stats.pending_teams ?? 0,
          valueSuffix: null,
          description: null
        },
        {
          label: 'Equipas inscritas',
          value: this.league.stats.total_teams ?? 0,
          valueSuffix: null,
          description: null
        },
        {
          label: this.$tc('players', 0),
          value: this.league.stats.total_players ?? 0,
          valueSuffix: null,
          description: null
        },
        {
          label: 'Fases (por torneio)',
          value: this.league.stages ? this.league.stages.length : 1,
          valueSuffix: null,
          description: null
        }
      ]
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "league": "Liga",
    "tournaments": "Torneio | Torneios",
    "publishedTournaments": "Torneios publicados",
    "teams": "Equipa | Equipas",
    "players": "Participante | Participantes"
  },
  "en": {
    "league": "League",
    "tournaments": "Torneio | Torneios",
    "publishedTournaments": "Torneios publicados",
    "teams": "Team | Teams",
    "players": "Participant | Participants"
  }
}
</i18n>
