<template>
  <div v-if="edition">
    <DashboardHero :title="edition.name" class="mt-2 mb-5" type="cover" background="sports-15" :tag="$t('edition')">
      <template #footer>
        <div v-if="edition.created_at">
          <fw-label color="text-white opacity-50">{{ $t('createdAt') }}</fw-label>
          <div>{{ edition.created_at | formatDateTime }}</div>
        </div>
        <div v-if="edition.start_at">
          <fw-label color="text-white opacity-50">{{ $t('startsAt') }}</fw-label>
          <div>{{ edition.start_at.date | formatDate }}</div>
        </div>
        <div v-if="edition.end_at">
          <fw-label color="text-white opacity-50">{{ $t('endsAt') }}</fw-label>
          <div>{{ edition.end_at.date | formatDate }}</div>
        </div>
      </template>
    </DashboardHero>

    <!-- <fw-panel v-if="editionStats" title="Estatísticas" class="my-5">
      <PanelStats :stats="editionStats" :stats-warning="false" />
    </fw-panel> -->

    <fw-panel title="Ligas" class="my-5">
      <template #toolbar>
        <b-dropdown aria-role="list" position="is-bottom-left" class="paddingless">
          <fw-button-dropdown slot="trigger" type="xlight" class="flex flex-col" :label="$t('export')" />
          <b-dropdown-item class="w-56" custom aria-role="menuitem">
            <ButtonExportStatsCSV :edition-key="edition.key"></ButtonExportStatsCSV>
            <ButtonExportEmailsCSV :edition-key="edition.key"></ButtonExportEmailsCSV>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-5">
        <a
          v-for="league in leagues"
          :key="league.key + '-' + edition.key"
          href=""
          class="group bg-gray-200 flex-col flex p-5 rounded-xl hover:bg-opacity-70"
          @click.prevent="$emit('go-to-view', 'dashboard', { leagueKey: league.key })"
        >
          <div class="flex gap-3 justify-between items-center mb-3">
            <div>
              <fw-icon-cube class="w-6 h-6" />
            </div>
            <div v-if="league.inbox.unread" class="flex-1 font-bold flex items-center justify-end gap-1 text-primary">
              <fw-icon-inbox class="w-5 h-5 flex-shrink-0" /> {{ league.inbox.unread }}
            </div>
          </div>
          <div class="flex-1 flex flex-col justify-end gap-2">
            <div class="font-bold uppercase flex-1">
              <v-clamp autoresize :max-lines="3">
                {{ league.title[language] }}
              </v-clamp>
            </div>
            <div v-if="league.stats" class="flex flex-col gap-0.5 font-semibold text-gray-500 text-sm">
              <div>{{ league.stats?.total_teams }} {{ $tc('teams', league.stats?.total_teams) }}</div>
              <div>{{ league.stats?.total_players }} {{ $tc('player', league.stats?.total_players) }}</div>
            </div>
          </div>
        </a>
      </div>
    </fw-panel>
  </div>
</template>

<script>
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import ButtonExportStatsCSV from '@/components/buttons/ButtonExportStatsCSV'
import ButtonExportEmailsCSV from '@/components/buttons/ButtonExportEmailsCSV'

export default {
  components: {
    DashboardHero,
    ButtonExportStatsCSV,
    ButtonExportEmailsCSV
  },

  props: {
    edition: {
      type: Object,
      default: () => {}
    },
    leagues: {
      type: Array,
      default: () => []
    },
    checks: {
      type: Object,
      default: () => {}
    },
    users: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    editionStats() {
      if (this.edition.stats) {
        return [
          {
            label: 'Torneios',
            value: this.edition.stats.tournaments ?? 0,
            valueSuffix: null,
            description: null
          },
          {
            label: 'Equipas aprovadas',
            value: this.edition.stats.approved_teams ?? 0,
            valueSuffix: null,
            description: null
          },
          {
            label: 'Equipas por aprovar',
            value: this.edition.stats.pending_teams ?? 0,
            valueSuffix: null,
            description: null
          },
          {
            label: 'Equipas inscritas',
            value: this.edition.stats.total_teams ?? 0,
            valueSuffix: null,
            description: null
          },
          {
            label: 'Participantes',
            value: this.edition.stats.total_players ?? 0,
            valueSuffix: null,
            description: null
          }
        ]
      }
      return []
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {
    "createdAt": "Criada em",
    "startsAt": "Início",
    "endsAt": "Fim",
    "edition": "Edição",
    "teams": "Equipa | Equipas",
    "player": "Participante | Participantes",
    "export": "Exportar"
  },
  "en": {
    "createdAt": "Created at",
    "startsAt": "Starts at",
    "endsAt": "Ends at",
    "edition": "Edition",
    "teams": "Team | Teams",
    "player": "Player | Players",
    "export": "Export"
  }
}
</i18n>
