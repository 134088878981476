<template>
  <SidebarBase sticky>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar
          :active="!view || view == 'dashboard'"
          :label="$t('dashboard')"
          icon="dashboard"
          :to="{ name: 'manage-edition', params: { view: 'dashboard' } }"
        />
        <ButtonSidebar
          v-if="false"
          :active="!view || view == 'inbox'"
          :label="$t('inbox')"
          icon="inbox"
          counter="99"
          :to="{ name: 'manage-edition', params: { view: 'inbox' } }"
        />

        <div
          v-for="league in leagues.toSorted((a, b) => a.title[language].localeCompare(b.title[language]))"
          :key="league.key"
        >
          <ButtonSidebar
            v-if="(isOnlyTeamGroupManager && league.type == 'academic') || !isOnlyTeamGroupManager"
            :truncate-label="false"
            :active="leagueKey && leagueKey == league.key"
            :label="league.title[language]"
            :to="{ name: 'manage-edition-league', params: { view: 'dashboard', leagueKey: league.key } }"
          >
            <template #svg>
              <fw-icon-cube class="w-6 h-6" />
            </template>
            <template #suffix>
              <fw-icon-chevron-up v-if="leagueKey && leagueKey == league.key" class="w-4 h-4" />
              <fw-icon-chevron-down v-else class="w-4 h-4" />
            </template>
          </ButtonSidebar>

          <VerticalSteps
            v-if="leagueKey && leagueKey == league.key"
            :steps="isOnlyTeamGroupManager && league.type == 'academic' ? teamGroupManagerSteps : verticalSteps"
            :current-step="view ?? 'dashboard'"
            class="mb-2"
            :truncate-text="true"
            @clicked="$router.push({ name: 'manage-edition-league', params: { view: $event, leagueKey: league.key } })"
          />
        </div>

        <ButtonSidebar
          v-if="checks.user.isGlobalManager"
          :active="view == 'people'"
          :label="$t('people')"
          icon="people"
          :to="{ name: 'manage-edition', params: { view: 'people' } }"
        />
        <ButtonSidebar
          v-if="checks.user.isGlobalManager || checks.user.isAnyManager"
          :label="$t('chats')"
          :active="view == 'chats'"
          icon="chats"
          :to="{ name: 'manage-edition', params: { view: 'chats' } }"
        />

        <ButtonSidebar
          v-if="checks.user.isGlobalManager"
          :label="$t('activity')"
          :active="view == 'activity'"
          icon="activity"
          :to="{ name: 'manage-edition', params: { view: 'activity' } }"
        />
        <ButtonSidebar
          v-if="checks.user.isGlobalManager"
          :label="$t('notifications')"
          :active="view == 'notifications'"
          icon="message-sent"
          :to="{ name: 'manage-edition', params: { view: 'notifications' } }"
        />

        <ButtonSidebar
          v-if="checks.user.isGlobalManager"
          :active="view == 'settings' && !leagueKey"
          :label="$t('settings')"
          icon="settings"
          :to="{ name: 'manage-edition', params: { view: 'settings' } }"
        />
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
    VerticalSteps
  },

  props: {
    leagues: {
      type: Array,
      default: () => {}
    },
    checks: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      verticalSteps: [
        { value: 'tournaments', text: 'Torneios' },
        { value: 'teams', text: 'Equipas' },
        { value: 'messages', text: this.$t('bulkMessages') },
        { value: 'settings', text: 'Configurações' }
      ],
      teamGroupManagerSteps: [
        { value: 'tournaments', text: 'Torneios' },
        { value: 'teams', text: 'Equipas' }
      ]
    }
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },

    view() {
      return this.$route.params.view ?? 'dashboard'
    },

    leagueKey() {
      return this.$route.params.leagueKey
    },

    isOnlyTeamGroupManager() {
      return (
        this.checks.user.isTeamGroupManager && !this.checks.user.isGlobalManager && !this.checks.user.isLeagueManager
      )
    }
  },

  methods: {}
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
{
  "pt": {
    "dashboard": "Dashboard",
    "activity": "Atividade",
    "people": "Pessoas",
    "notifications": "Notificações",
    "settings": "Configurações",
    "metadata": "Metadados",
    "reports": "Mapas",
    "league": "Liga",
    "inbox": "Inbox",
    "chats": "Chats",
    "bulkMessages": "Comunicações"
  },
  "en": {
    "dashboard": "Dashboard",
    "activity": "Activity",
    "people": "People",
    "notifications": "Notifications",
    "settings": "Settings",
    "metadata": "Metadata",
    "reports": "Reports",
    "league": "League",
    "inbox": "Inbox",
    "chats": "Chats",
    "bulkMessages": "Bulk Messages"
  }
}
</i18n>
