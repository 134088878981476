<template>
  <div>
    <ChoosePeopleModal
      :title="title"
      :instructions="instructions"
      :multiselect="multiselect"
      :not-alowed-users="notAlowedUsers"
      :invite-people="false"
      :endpoint="endpoint"
      @selected-updated="selectedPeople"
      @confirm="saveSelection"
      @close="$emit('close')"
    >
      <template #after-list>
        <div v-if="selectedUsers.length" class="ml-2">
          <div class="mt-5">
            <fw-label>{{ $t('teamGroupsToAdd') }}</fw-label>

            <multiselect
              v-model="selectedTeamGroups"
              :options="Object.values(teamGroups)"
              :show-labels="false"
              :clear-on-select="false"
              :allow-empty="false"
              :placeholder="$t('selectTeamGroupsToAdd')"
              :multiple="true"
              track-by="key"
              label="name"
              :searchable="true"
              :custom-label="teamGroupLabel"
            ></multiselect>

            <fw-tip v-if="$v.selectedTeamGroups.$error" error>
              {{ $t('chooseAtLeastOneTeamGroup') }}
            </fw-tip>
          </div>
        </div>
      </template>
    </ChoosePeopleModal>

    <fw-tip v-if="!selectedUsers.length && hasErrors" error>
      <span> {{ $t('selectAtLeastOne') }}</span>
    </fw-tip>
  </div>
</template>

<script>
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import Multiselect from 'vue-multiselect'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    ChoosePeopleModal,
    Multiselect
  },
  props: {
    multiselect: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    instructions: {
      type: String,
      default: ''
    },
    notAlowedUsers: {
      //not alowed user keys
      type: Array,
      default: function() {
        return []
      }
    },
    endpoint: {
      type: Function
    },
    teamGroups: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      hasErrors: false,
      searchQueryId: '',
      selectedUsers: [],
      selectedTeamGroups: [],
      debounceTimer: null,
      searching: false,
      totalPages: 1,
      totalLimit: 20,
      page: 1
    }
  },

  validations: {
    selectedUsers: { required, min: minLength(1) },
    selectedTeamGroups: { required, min: minLength(1) }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    confirmDisabled() {
      return this.$v.$invalid
    },

    language() {
      return this.$store.state.language || 'pt'
    }
  },

  methods: {
    teamGroupLabel(teamGroup) {
      return `${teamGroup.organic_unit.name} - ${teamGroup.name}`
    },

    selectedPeople(selected) {
      console.log('Select THIS ONE', selected)
      this.selectedUsers = selected
    },

    saveSelection() {
      this.$v.$touch()
      console.log('this.$v.$invalid :>> ', this.$v.$invalid)
      if (this.$v.$invalid) return

      this.$emit('selected', {
        users: this.selectedUsers,
        selectedTeamGroups: this.selectedTeamGroups.map(el => el.key)
      })

      this.$v.$reset
      this.selectedUsers = []
      this.selectedTeamGroups = []
      this.$emit('close')
    }
  }
}
</script>

<style>
.invite-users.keyboard_input .textarea:focus,
.invite-users.keyboard_input .input:focus,
.invite-users.keyboard_input .taginput .taginput-container.is-focusable:focus,
.invite-users.keyboard_input .datepicker .dropdown .input:focus,
.invite-users.keyboard_input .datepicker .dropdown-trigger .input[readonly]:focus,
.invite-users.keyboard_input button:focus,
.invite-users.keyboard_input select:focus,
.invite-users.keyboard_input button:focus {
  outline: solid 2px rgba(3, 164, 121, 0.7) !important;
  outline-offset: 2px !important;
}
</style>

<i18n>
{
  "pt": {
    "noDataFound": "Sem resultados",
    "searchInUC": "Pesquisar na UC",
    "useInstitutionalAccount": "Por favor use a conta institucional do(a) utilizador(a)",
    "search": "Pesquisar...",
    "searchText": "Pesquisar",
    "results": "Resultados",
    "limitedNumResults": "Esta pesquisa apresenta um número limitado de resultados",
    "defineKeywords": "Defina melhor as palavras chaves para encontrar o registo que procura",
    "permissionsToAdd": "Permissões para adicionar",
    "chooseAtLeastOne": "Escolha pelo menos uma permissão",
    "name": "Nome",
    "permissions": "Permissões",
    "cancel": "Cancelar",
    "add": "Adicionar",
    "selectAtLeastOne": "Escolha pelo menos um(a) utilizador(a)",
    "teamGroupsToAdd": "Núcleos",
    "chooseAtLeastOneTeamGroup": "Escolha pelo menos um núcleo",
    "selectTeamGroupsToAdd": "Selecione pelo menos um núcleo"
  },
  "en": {
    "noDataFound": "No data found",
    "searchInUC": "Search in UC",
    "useInstitutionalAccount": "Please use the user's institutional account",
    "search": "Search...",
    "searchText": "Search",
    "results": "Results",
    "limitedNumResults": "This search presents a limited number of results",
    "defineKeywords": "Better define your keywords to find the record you are looking for",
    "permissionsToAdd": "Permissions to add",
    "chooseAtLeastOne": "Choose at least one permission",
    "permissions": "Permissions",
    "cancel": "Cancel",
    "add": "Add",
    "selectAtLeastOne": "Choose at least one user",
    "teamGroupsToAdd": "Núcleos",
    "chooseAtLeastOneTeamGroup": "Choose at least one group",
    "selectTeamGroupsToAdd": "Selecione pelo menos um núcleo"
  }
}
</i18n>
