var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex items-center text-left gap-3",attrs:{"to":{
    name: 'manage-tournament',
    params: {
      tournamentKey: _vm.tournament.key,
      editionKey: _vm.tournament.edition_key,
      leagueKey: _vm.tournament.league_key,
      tournamentKey: _vm.tournament.key,
      view: 'dashboard'
    }
  }}},[_c('div',{staticClass:"flex-shrink-0 font-medium flex items-center gap-1"},[_c('fw-icon-arrow-right',{staticClass:"w-5 h-5 text-gray-500"})],1),_c('div',{staticClass:"flex-1 font-medium capitalize flex min-w-0 flex-col"},[(_vm.tournament.title)?_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.tournament.title[_vm.language])+" ")]):_c('span',{staticClass:"text-sm text-gray-500"},[_vm._v(_vm._s(_vm.$t('notDefined')))]),(_vm.sportName)?_c('span',{staticClass:"text-xs font-semibold text-gray-500"},[_vm._v(_vm._s(_vm.sportName))]):_vm._e()]),_c('div',{staticClass:"w-28 flex-shrink-0"},[_c('fw-tag',{attrs:{"type":'medium',"size":"xs"}},[_vm._v(" "+_vm._s(_vm.$t(`gender.${_vm.tournament.gender}`))+" ")])],1),_c('div',{staticClass:"w-40 flex-shrink-0"},[(_vm.tournament.archived_at)?_c('fw-tag',{attrs:{"type":'xlight',"size":"xs"}},[_vm._v(" "+_vm._s(_vm.$t(`archived`))+" ")]):_c('fw-tag',{attrs:{"type":_vm.tournament.is_visible ? (_vm.tournament.is_signup_open ? 'primary' : 'light-primary') : 'light-orange',"size":"xs"}},[_vm._v(" "+_vm._s(_vm.tournament.is_visible ? (_vm.tournament.is_signup_open ? _vm.$t(`signupOpen`) : _vm.$t(`published`)) : _vm.$t(`draft`))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }