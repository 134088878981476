<template>
  <div>
    <fw-heading size="xl">{{ $t('people') }}</fw-heading>

    <LoadingPlaceholder v-if="loading" />

    <fw-panel
      v-if="!loading"
      :title="$t('globalManagers')"
      boxed="xs"
      custom-class="bg-white"
      class="my-5"
      :counter="globalManagers.length"
    >
      <ExpandList
        :add-title="$t('addEditionManagers')"
        :empty="!globalManagers || globalManagers < 1"
        :show-add-items="false"
        @add-element="choosePeople('globalManagers', null)"
      >
        <template #list>
          <Person
            v-for="(userKey, index) in globalManagers"
            :key="userKey"
            :class="{
              'border-b border-gray-100': index < globalManagers - 1
            }"
            :person="users[userKey]"
            :selectable="false"
            :clickable="canEdit"
            @clicked="openPersonDetails(users[userKey], 'globalManagers')"
          >
            <!-- <template v-if="userKey != loggedUser.key && canEdit" #options>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button
                    type="basic-action"
                    custom-class="w-full"
                    @click.native="deleteManager(userKey, 'global-manager')"
                  >
                    {{ $t('delete') }}</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template> -->
          </Person>
        </template>
      </ExpandList>
    </fw-panel>

    <fw-panel
      v-if="!loading"
      :title="$t('leagueManagers')"
      boxed="xs"
      custom-class="bg-white"
      class="my-5"
      :counter="leaguesManagers.length"
    >
      <ExpandList
        :add-title="$t('addLeagueManagers')"
        :empty="!leaguesManagers || leaguesManagers < 1"
        :show-add-items="canEdit && validations.can_add_league_managers"
        @add-element="choosePeople('leaguesManagers')"
      >
        <template #list>
          <Person
            v-for="(userLeagues, userKey, index) in sortedLeagues"
            :key="userKey"
            :class="{
              'border-b border-gray-100': index < leaguesManagers - 1
            }"
            :clickable="canEdit"
            :person="users[userKey]"
            :selectable="false"
            @clicked="openPersonDetails(users[userKey], 'leaguesManagers')"
          >
            <template #secondline>
              <div v-if="users[userKey]" class="text-sm text-gray-500 font-normal flex gap-2 items-center">
                {{ users[userKey].email }}
              </div>
              <div class="text-sm text-gray-500 font-normal flex gap-2 items-center pt-2">
                <template v-for="league in userLeagues">
                  <fw-tag :key="league.key" type="light-border-box" size="xs">{{ league.title[language] }}</fw-tag>
                </template>
              </div>
            </template>
            <template v-if="userKey != loggedUser.key && canEdit" #options>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button
                    type="basic-action"
                    custom-class="w-full"
                    @click.native="deleteManager(userKey, 'leaguesManagers')"
                  >
                    {{ $t('delete') }}</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
        </template>
      </ExpandList>
    </fw-panel>

    <fw-panel
      v-if="!loading"
      :title="$t('teamGroupsManagers')"
      boxed="xs"
      custom-class="bg-white"
      class="my-5"
      :counter="teamGroupsManagers.length"
    >
      <ExpandList
        :add-title="$t('addTeamGroupsManagers')"
        :empty="!teamGroupsManagers || teamGroupsManagers < 1"
        :show-add-items="canEdit && validations.can_add_team_group_managers"
        @add-element="choosePeople('teamGroupsManagers')"
      >
        <template #list>
          <Person
            v-for="(userTeamGroups, userKey, index) in sortedTeamGroups"
            :key="userKey"
            :class="{
              'border-b border-gray-100': index < teamGroupsManagers - 1
            }"
            :clickable="canEdit"
            :person="users[userKey]"
            :selectable="false"
            @clicked="openPersonDetails(users[userKey], 'teamGroupsManagers')"
          >
            <template #secondline>
              <div v-if="users[userKey]" class="text-sm text-gray-500 font-normal flex gap-2 items-center">
                {{ users[userKey].email }}
              </div>
              <div class="text-sm text-gray-500 font-normal flex gap-2 items-center pt-2">
                <template v-for="teamGroup in userTeamGroups">
                  <fw-tag :key="teamGroup.key" type="light-border-box" size="xs">{{
                    `${teamGroup.organic_unit.name} - ${teamGroup.name}`
                  }}</fw-tag>
                </template>
              </div>
            </template>
            <template v-if="userKey != loggedUser.key && canEdit" #options>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button
                    type="basic-action"
                    custom-class="w-full"
                    @click.native="deleteManager(userKey, 'teamGroupsManagers')"
                  >
                    {{ $t('delete') }}</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
        </template>
      </ExpandList>
    </fw-panel>

    <fw-panel-info debug label="People (raw)">
      <json-viewer :value="{ users, globalManagers, leaguesManagers, teamGroupsManagers }"></json-viewer>
    </fw-panel-info>

    <fw-modal
      :active.sync="activeChoosingPeopleModal"
      :can-cancel="true"
      boxed="xs"
      size="min"
      width="42rem"
      @close="closePersonDetails"
    >
      <ChoosePeopleModal
        v-if="choosingPeopleType == 'globalManagers'"
        :title="selectPeopleTitle"
        :instructions="selectPeopleInstructions"
        :multiselect="true"
        :not-alowed-users="excludeUsers"
        :invite-people="false"
        :endpoint="globalManagersSearch"
        @close="closeModal"
      ></ChoosePeopleModal>
      <ModalChooseLeaguesManagers
        v-else-if="choosingPeopleType == 'leaguesManagers'"
        :title="selectPeopleTitle"
        :instructions="selectPeopleInstructions"
        :multiselect="true"
        :endpoint="globalManagersSearch"
        :not-alowed-users="excludeUsers"
        :leagues="leagues"
        @selected="selectedLeaguesManagers"
        @close="closeModal"
      ></ModalChooseLeaguesManagers>
      <ModalChooseTeamGroupsManagers
        v-else
        :title="selectPeopleTitle"
        :instructions="selectPeopleInstructions"
        :multiselect="true"
        :endpoint="globalManagersSearch"
        :not-alowed-users="excludeUsers"
        :team-groups="teamGroups"
        @selected="selectedTeamGroupsManagers"
        @close="closeModal"
      ></ModalChooseTeamGroupsManagers>
    </fw-modal>

    <fw-modal
      :active.sync="activeUserDetailsModal"
      :can-cancel="true"
      boxed="xs"
      size="min"
      width="42rem"
      @close="closePersonDetails"
    >
      <ManageUserDetails
        v-if="activeUserDetailsModal"
        :user="selectedUser"
        :leagues="leagues"
        :user-leagues="leaguesManagers[selectedUser?.key] ?? []"
        :user-team-groups="teamGroupsManagers[selectedUser?.key] ?? []"
        :saving-data="savingData"
        :team-groups="teamGroups"
        :role="choosingPeopleType"
        :validations="validations"
        @save="saveSelection"
        @close="closePersonDetails"
      />
    </fw-modal>
  </div>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ModalChooseLeaguesManagers from '@/components/modals/ModalChooseLeaguesManagers'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ManageUserDetails from '@/components/modals/ManageUserDetails'
import ModalChooseTeamGroupsManagers from '@/components/modals/ModalChooseTeamGroupsManagers'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    ExpandList,
    Person,
    ChoosePeopleModal,
    ModalChooseLeaguesManagers,
    ModalChooseTeamGroupsManagers,
    LoadingPlaceholder,
    ManageUserDetails
  },

  props: {
    edition: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      canEdit: true,
      loading: true,
      savingData: false,
      users: {},
      leaguesManagersByLeagueKey: {},
      leaguesManagers: {},
      teamGroupsManagers: {},
      teamGroupsManagersByGroupKey: {},
      globalManagers: [],
      teamGroups: {},
      leagues: {},
      activeUserDetailsModal: false,
      activeChoosingPeopleModal: false,
      selectedUser: {},
      choosingPeopleType: null,
      choosingPeopleLeague: null,
      choosingPeopleTitles: {
        globalManagers: {
          title: this.$t('selectEditionManager'),
          instructions: this.$t('chooseEditionManager')
        },
        leaguesManagers: {
          title: this.$t('selectLeagueManager'),
          instructions: this.$t('chooseLeagueManager')
        },
        teamGroupsManagers: {
          title: this.$t('selectTeamGroupsManager'),
          instructions: this.$t('chooseTeamGroupsManager')
        }
      },
      validations: {
        can_add_league_managers: true,
        can_add_team_group_managers: true
      }
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    selectPeopleTitle() {
      return this.choosingPeopleType ? this.choosingPeopleTitles[this.choosingPeopleType].title : ''
    },

    selectPeopleInstructions() {
      return this.choosingPeopleType ? this.choosingPeopleTitles[this.choosingPeopleType].instructions : ''
    },

    excludeUsers() {
      if (this.choosingPeopleType == 'globalManagers') {
        return this.globalManagers
      }

      if (this.choosingPeopleType == 'leaguesManagers') {
        return this.globalManagers.concat(Object.keys(this.leaguesManagers))
      }

      return this.globalManagers.concat(Object.keys(this.leaguesManagers)).concat(Object.keys(this.teamGroupsManagers))
    },

    sortedLeagues() {
      if (utils.isObjectEmpty(this.leaguesManagers) || utils.isObjectEmpty(this.leagues)) return {}

      const sortedLeagues = {}
      for (const [userKey, leagueKeys] of Object.entries(this.leaguesManagers)) {
        sortedLeagues[userKey] = leagueKeys
          .map(key => this.leagues[key])
          .sort((a, b) => a.title[this.language].localeCompare(b.title[this.language]))
      }

      return sortedLeagues
    },

    sortedTeamGroups() {
      if (utils.isObjectEmpty(this.teamGroupsManagers) || utils.isObjectEmpty(this.teamGroups)) return {}

      const sortedLeagues = {}
      for (const [userKey, leagueKeys] of Object.entries(this.teamGroupsManagers)) {
        sortedLeagues[userKey] = leagueKeys
          .map(key => this.teamGroups[key])
          .sort((a, b) => `${a.organic_unit.name} - ${a.name}`.localeCompare(`${b.organic_unit.name} - ${b.name}`))
      }

      return sortedLeagues
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    async getData() {
      this.loading = true
      await Promise.all([this.getRoles(), this.getLeagues(), this.getTeamGroups()])
      await utils.sleep(500)
      this.loading = false
    },

    async getRoles() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getEditionPermissions(this.edition.key)
        console.log('getEditionPermissions :>> ', response)
        this.validations = response.validations
        this.users = response.users
        this.leaguesManagersByLeagueKey = response.leagues ?? {}
        this.teamGroupsManagersByGroupKey = response.team_groups ?? {}
        const usersLeagues = {}
        for (const [leagueKey, userKeys] of Object.entries(this.leaguesManagersByLeagueKey)) {
          for (const userKey of userKeys) {
            ;(usersLeagues[userKey] ||= []).push(leagueKey)
          }
        }

        const usersTeamGroups = {}
        for (const [teamGroupKey, userKeys] of Object.entries(this.teamGroupsManagersByGroupKey)) {
          for (const userKey of userKeys) {
            ;(usersTeamGroups[userKey] ||= []).push(teamGroupKey)
          }
        }

        this.teamGroupsManagers = usersTeamGroups

        // usersLeagues = {
        //     "zbbjn8": [
        //         "t2wlzzq66zxcds4h",
        //         "ea2uu986wu2iwqrp",
        //         "x307271cqy32iilh"
        //     ],
        //     "odsgzu": [
        //         "t2wlzzq66zxcds4h"
        //     ]
        // }
        this.leaguesManagers = usersLeagues

        // global_managers = ['1x6t4y']
        this.globalManagers = response.global_managers
      })
    },

    async getTeamGroups() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getTeamGroups({
          sort: 'name',
          direction: 'asc',
          with_org_units: true,
          limit: 50
        })
        console.log('getTeamGroups :>> ', response)
        for (let index = 0; index < response.groups.length; index++) {
          const group = response.groups[index]
          this.teamGroups[group.key] = response.groups[index]
        }
        console.log('this.teamGroups :>> ', this.teamGroups)
      })
    },

    async getLeagues() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.managementGetEditionLeagues(this.edition.key)
        console.log('getEditionPermissions :>> ', response)
        for (let index = 0; index < response.leagues.length; index++) {
          const league = response.leagues[index]
          this.leagues[league.key] = response.leagues[index]
        }
      })
    },

    async globalManagersSearch(params) {
      return await this.api.searchUsersForEditionPermissions(this.edition.key, params)
    },

    choosePeople(type) {
      console.log('choosePeople :>> ', type)
      this.choosingPeopleType = type
      this.activeChoosingPeopleModal = true
    },

    openPersonDetails(user, roleType) {
      console.log('openPersonDetails :>> ', user, roleType)
      this.selectedUser = user
      this.activeUserDetailsModal = true
      this.activeChoosingPeopleModal = false
      this.choosingPeopleType = roleType
    },

    closePersonDetails() {
      console.log('closePersonDetails')
      this.activeUserDetailsModal = false
      this.selectedUser = {}

      this.choosingPeopleType = null
    },

    saveSelection(item) {
      console.log('saveSelection :>> ', item, this.choosingPeopleType)
      const user = item.users[0]

      if (this.choosingPeopleType == 'teamGroupsManagers') {
        let old = this.teamGroupsManagers[user?.key]
        let news = item.selectedTeamGroups
        let removed = old.filter(el => !news.includes(el))
        let added = news.filter(el => !old.includes(el))

        if (removed.length) {
          const payload = {}
          for (const teamGroupKey of removed) {
            payload[teamGroupKey] = [user.key]
          }

          this.deletePermissions({ team_groups: payload }, () => {
            this.$set(
              this.teamGroupsManagers,
              user.key,
              this.teamGroupsManagers[user.key].filter(key => !removed.includes(key))
            )

            for (const [leagueKey, userkeys] of Object.entries(this.teamGroupsManagersByGroupKey)) {
              if (removed.includes(leagueKey)) {
                this.$set(
                  this.teamGroupsManagersByGroupKey,
                  leagueKey,
                  userkeys.filter(key => key != user.key)
                )
              }
            }
          })
        }

        if (added.length) {
          this.selectedTeamGroupsManagers(item)
        }
      } else if (this.choosingPeopleType === 'leaguesManagers') {
        let old = this.leaguesManagers[user.key]
        let news = item.selectedLeagues
        let removed = old.filter(el => !news.includes(el))
        let added = news.filter(el => !old.includes(el))

        if (removed.length) {
          const payload = {}
          for (const teamGroupKey of removed) {
            payload[teamGroupKey] = [user.key]
          }

          this.deletePermissions({ leagues: payload }, () => {
            this.$set(
              this.leaguesManagers,
              user.key,
              this.leaguesManagers[user.key].filter(key => !removed.includes(key))
            )

            for (const [leagueKey, userkeys] of Object.entries(this.leaguesManagersByLeagueKey)) {
              if (removed.includes(leagueKey)) {
                this.$set(
                  this.leaguesManagersByLeagueKey,
                  leagueKey,
                  userkeys.filter(key => key != user.key)
                )
              }
            }
          })
        }

        if (added.length) {
          this.selectedLeaguesManagers(item)
        }
      }
    },

    selectedLeaguesManagers({ users, selectedLeagues }) {
      console.log('selectedLeaguesManagers :>> ', { users, selectedLeagues })
      if (!users || !selectedLeagues) return
      const payload = {}
      const usersKeys = users.map(el => el.key)
      for (const leagueKey of selectedLeagues) {
        if (payload[leagueKey]) payload[leagueKey] = payload[leagueKey].concat(usersKeys)
        else payload[leagueKey] = usersKeys
      }

      this.updatePermissions({ leagues: payload }, () => this.addToLeaguesManagersList(users, selectedLeagues))
    },

    selectedTeamGroupsManagers({ users, selectedTeamGroups }) {
      console.log('selectedTeamGroupsManagers :>> ', { users, selectedTeamGroups })
      if (!users || !selectedTeamGroups) return
      const payload = {}
      const usersKeys = users.map(el => el.key)
      for (const leagueKey of selectedTeamGroups) {
        if (payload[leagueKey]) payload[leagueKey] = payload[leagueKey].concat(usersKeys)
        else payload[leagueKey] = usersKeys
      }

      this.updatePermissions({ team_groups: payload }, () =>
        this.addToTeamGroupsManagersList(users, selectedTeamGroups)
      )
    },

    addToTeamGroupsManagersList(users, selectedTeamGroups) {
      console.log('addToTeamGroupsManagersList', { users, selectedTeamGroups })
      const usersKeys = []
      for (const user of users) {
        usersKeys.push(user.key)
        if (!this.users[user.key]) this.users[user.key] = user

        if (!this.teamGroupsManagers[user.key]) this.$set(this.teamGroupsManagers, user.key, selectedTeamGroups)
        else
          this.$set(this.teamGroupsManagers, user.key, [
            ...new Set(this.teamGroupsManagers[user.key].concat(selectedTeamGroups))
          ])
      }

      for (const leagueKey of selectedTeamGroups) {
        if (this.teamGroupsManagersByGroupKey[leagueKey]) {
          this.$set(
            this.teamGroupsManagersByGroupKey,
            leagueKey,
            this.teamGroupsManagersByGroupKey[leagueKey].concat(usersKeys)
          )
        } else this.$set(this.teamGroupsManagersByGroupKey, leagueKey, usersKeys)
      }
    },

    addToLeaguesManagersList(users, selectedLeagues) {
      console.log('addToLeaguesManagersList', { users, selectedLeagues })
      const usersKeys = []
      for (const user of users) {
        usersKeys.push(user.key)
        if (!this.users[user.key]) this.users[user.key] = user

        if (!this.leaguesManagers[user.key]) this.$set(this.leaguesManagers, user.key, selectedLeagues)
        else
          this.$set(this.leaguesManagers, user.key, [
            ...new Set(this.leaguesManagers[user.key].concat(selectedLeagues))
          ])
      }

      for (const leagueKey of selectedLeagues) {
        if (this.leaguesManagersByLeagueKey[leagueKey]) {
          this.$set(
            this.leaguesManagersByLeagueKey,
            leagueKey,
            this.leaguesManagersByLeagueKey[leagueKey].concat(usersKeys)
          )
        } else this.$set(this.leaguesManagersByLeagueKey, leagueKey, usersKeys)
      }
    },

    deleteManager(userKey, role) {
      console.log('deleteManager :>> ', { userKey, role })
      if (role == 'leaguesManagers') {
        const payload = {}
        for (const leagueKey of this.leaguesManagers[userKey]) {
          payload[leagueKey] = [userKey]
        }
        console.log('payload :>> ', payload)
        this.deletePermissions({ leagues: payload }, () => this.deleteFromLeaguesManagersList(userKey))
      } else if (role == 'teamGroupsManagers') {
        const payload = {}
        for (const leagueKey of this.teamGroupsManagers[userKey]) {
          payload[leagueKey] = [userKey]
        }

        this.deletePermissions({ team_groups: payload }, () => this.deleteFromTeamGroupsManagersList(userKey))
      }
    },

    deleteFromLeaguesManagersList(userKey) {
      this.$delete(this.leaguesManagers, userKey)
      this.$delete(this.users, userKey)

      for (const [leagueKey, userkeys] of Object.entries(this.leaguesManagersByLeagueKey)) {
        this.leaguesManagersByLeagueKey[leagueKey] = userkeys.filter(key => key != userKey)
      }
    },

    deleteFromTeamGroupsManagersList(userKey) {
      this.$delete(this.teamGroupsManagers, userKey)
      this.$delete(this.users, userKey)

      for (const [leagueKey, userkeys] of Object.entries(this.teamGroupsManagersByGroupKey)) {
        this.teamGroupsManagersByGroupKey[leagueKey] = userkeys.filter(key => key != userKey)
      }
    },

    async updatePermissions(payload, runNext) {
      // payload: { "leagues": { leagueKey: [ userKey ] } }
      this.savingData = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateEditionPermissions(this.edition.key, payload)
          console.log('updateEditionPermissions :>> ', response)
          if (runNext) runNext(response)
        },
        async () => {
          await utils.sleep(500)
          this.savingData = false
        }
      )
    },

    async deletePermissions(payload, runNext) {
      // payload: { "leagues": { leagueKey: [ userKey ] } }
      this.savingData = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.deleteEditionPermissions(this.edition.key, payload)
          console.log('deleteEditionPermissions :>> ', response)
          runNext(response)
        },
        async () => {
          await utils.sleep(500)
          this.savingData = false
        }
      )
    },

    closeModal() {
      this.choosingPeopleType = null
      this.activeChoosingPeopleModal = false
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "people": "Pessoas",
    "globalManagers": "Gestor(es) global",
    "addEditionManagers": "Adicionar gestor(es) da edição",
    "delete": "Remover",
    "leagueManagers": "Gestor(es) de liga",
    "addLeagueManagers": "Adicionar gestor(es) da liga",
    "teamGroupsManagers": "Gestor(es) de núcleo",
    "addTeamGroupsManagers": "Adicionar gestor(es) de núcleo",
    "chooseEditionManager": "Escolha o(s) gestor(es) da edição",
    "chooseLeagueManager": "Selecione o(s) utilizador(es) que quer como gestor(es) da liga:",
    "chooseTeamGroupsManager": "Escolha o(s) gestor(es) do núcleo",
    "selectEditionManager": "Selecione o(s) utilizador(es) que quer como gestor(es) da edição:",
    "selectLeagueManager": "Gestores de liga",
    "selectTeamGroupsManager": "Gestores de núcleo",
    "selectedTeamGroupsManagers": "Selecione o(s) utilizador(es) que quer como gestor(es) do núcleo:",
    "isRoleIn": "É <b>{role}</b> nas ligas:",
    "roles": {
      "global-managers": "gestor da edição",
      "league-manager": "gestor de liga",
      "team-groups-manager": "gestor de núcleo"
    }
  },
  "en": {
    "people": "People",
    "globalManagers": "Global manager(s)",
    "addEditionManagers": "Add global manager(s)",
    "delete": "Delete",
    "leagueManagers": "League manager(s)",
    "addLeagueManagers": "Add league manager(s)",
    "teamGroupsManagers": "Gestor(es) de núcleo",
    "addTeamGroupsManagers": "Adicionar gestor(es) de núcleo",
    "chooseEditionManager": "Choose the global manager(s)",
    "chooseLeagueManager": "Choose the league manager(s)",
    "chooseTeamGroupsManager": "Choose the team groups manager(s)",
    "selectEditionManager": "Select the user(s) you want as global manager(s):",
    "selectLeagueManager": "Select the user(s) you want as league manager(s):",
    "selectTeamGroupsManager": "Select the user(s) you want as team groups manager(s):",
    "isRoleIn": "Is <b>{role}</b> in leagues:",
    "roles": {
      "global-managers": "Edition manager",
      "league-manager": "League manager",
      "team-groups-manager": "gestor de núcleo"
    }
  }
}
</i18n>
