<template>
  <fw-layout
    back-to="/manage"
    :loading="initialLoading"
    :loading-title="editionTitle || $t('edition')"
    :notfound="!loading && (!edition || !edition.key)"
    :full="view == 'chats'"
  >
    <template #header-nav>
      <div v-if="edition && edition.key" class="flex gap-4 items-center">
        <div class="flex-shrink-0">
          <fw-heading size="lg">
            <span class="text-gray-500 mr-1">{{ $t('edition') }}</span>
            <span class="font-bold">{{ edition.name }}</span>
          </fw-heading>
        </div>
        <div v-if="edition.is_current">
          <fw-tag size="sm" type="primary" custom-class="px-3">
            Atual
          </fw-tag>
        </div>
      </div>
    </template>

    <template #main-sidebar>
      <SidebarManageEdition :leagues="leagues" :view="view" :checks="checks" @go-to-view="goToView" />
    </template>

    <template #main-content>
      <PanelManageEditionDashboard
        v-if="!view || (view == 'dashboard' && !leagueKey)"
        :edition="edition"
        :leagues="leagues"
        @go-to-view="goToView"
      />
      <PanelManageEditionInbox v-else-if="view == 'inbox' && !leagueKey" :edition="edition" />
      <PanelManageEditionPeople v-else-if="view == 'people' && !leagueKey" :edition="edition" />
      <PanelManageEditionSettings
        v-else-if="view == 'settings' && !leagueKey"
        :edition="edition"
        :saving-data="savingData"
        @update-edition="updateEdition"
        @activate-group-league="activateGroupLeague"
      />
      <PanelManageEditionActivity v-else-if="view == 'activity'" :edition-key="editionKey" />
      <PanelManageEditionNotifications v-else-if="view == 'notifications'" :edition-key="editionKey" />
      <PanelManageEditionChats v-else-if="view == 'chats'" :edition-key="editionKey" />
      <PanelManageEditionLeagueDashboard
        v-else-if="view == 'dashboard' && leagueKey"
        :edition="edition"
        :league="league"
        :leagues="leagues"
        :league-key="leagueKey"
      />
      <PanelManageEditionLeagueTournaments
        v-else-if="view == 'tournaments' && leagueKey"
        :edition="edition"
        :league="league"
      />
      <PanelManageEditionLeagueTeams v-else-if="view == 'teams' && leagueKey" :edition="edition" :league="league" />
      <PanelManageEditionLeagueSettings
        v-else-if="view == 'settings' && leagueKey"
        :edition="edition"
        :league="league"
        :league-key="leagueKey"
        :saving-data="savingData"
        @update-league="updateLeague"
      />
      <PanelManageLeagueBulkMessages v-else-if="view == 'messages' && leagueKey" :edition="edition" :league="league" />
    </template>
  </fw-layout>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { EDITION_STATES } from '@/utils/index.js'

import SidebarManageEdition from '@/components/sidebars/manage/SidebarManageEdition'
import PanelManageEditionDashboard from '@/components/panels/manage/PanelManageEditionDashboard'
import PanelManageEditionChats from '@/components/panels/manage/PanelManageEditionChats'
import PanelManageEditionInbox from '@/components/panels/manage/PanelManageEditionInbox'
import PanelManageEditionPeople from '@/components/panels/manage/PanelManageEditionPeople'
import PanelManageEditionSettings from '@/components/panels/manage/PanelManageEditionSettings'
import PanelManageEditionActivity from '@/components/panels/manage/PanelManageEditionActivity'
import PanelManageEditionNotifications from '@/components/panels/manage/PanelManageEditionNotifications'
import PanelManageEditionLeagueDashboard from '@/components/panels/manage/PanelManageEditionLeagueDashboard'
import PanelManageEditionLeagueTournaments from '@/components/panels/manage/PanelManageEditionLeagueTournaments'
import PanelManageEditionLeagueTeams from '@/components/panels/manage/PanelManageEditionLeagueTeams'
import PanelManageEditionLeagueSettings from '@/components/panels/manage/PanelManageEditionLeagueSettings'
import PanelManageLeagueBulkMessages from '@/components/panels/manage/PanelManageLeagueBulkMessages'

export default {
  components: {
    SidebarManageEdition,
    PanelManageEditionDashboard,
    PanelManageEditionPeople,
    PanelManageEditionSettings,
    PanelManageEditionActivity,
    PanelManageEditionNotifications,
    PanelManageEditionLeagueDashboard,
    PanelManageEditionLeagueTournaments,
    PanelManageEditionLeagueTeams,
    PanelManageEditionInbox,
    PanelManageEditionLeagueSettings,
    PanelManageLeagueBulkMessages,
    PanelManageEditionChats
  },

  data() {
    return {
      edition: {},
      leagues: [],
      league: {}, // active league
      loading: true,
      savingData: false,
      initialLoading: true,
      editionStatesMap: EDITION_STATES
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    user() {
      return this.$store.getters.getUser
    },
    userPermissions() {
      return this.$store.getters.userPermissions
    },
    editionKey() {
      return this.$route.params.editionKey
    },
    editionTitle() {
      if (this.edition && this.edition.title) {
        return this.edition.title[this.language]
      }
      return null
    },
    leagueKey() {
      return this.$route.params.leagueKey
    },
    view() {
      return this.$route.params.view ?? 'dashboard'
    },

    // Checks
    checks() {
      return {
        user: this.userPermissions
      }
    }
  },

  watch: {
    view(newVal) {
      if (newVal) this.getCurrentViewData()
    },
    leagueKey(key, oldKey) {
      console.log('League key changed:', key, oldKey)
      if (key && key !== oldKey) this.getLeague()
    }
  },

  mounted() {
    this.getCurrentViewData(true)
    console.log('Current view (mounted):', this.view)
  },

  methods: {
    async getCurrentViewData(initialLoad) {
      console.log('Current view (after view changed):', this.view)

      if (initialLoad || !this.view || ['dashboard', 'metadata'].includes(this.view)) {
        await this.getEdition(initialLoad)

        if (this.leagueKey) {
          this.getLeague()
        }
      }
    },

    async getEdition(initialLoad) {
      this.loading = true

      utils.tryAndCatch(
        this,

        // Code to run on try
        async () => {
          this.edition = await this.api.managementGetEdition(this.editionKey)
          console.log('managementGetEdition :>> ', this.edition)
          this.leagues = this.edition.leagues

          this.saveLocalLastEdition()
        },

        // Finally
        () => {
          // Run just once
          if (initialLoad) {
            setTimeout(() => {
              this.initialLoading = false
            }, 750)
          }
          this.loading = false
        }
      )
    },

    async getLeague() {
      this.loading = true

      utils.tryAndCatch(
        this,

        // Code to run on try
        async () => {
          this.league = await this.api.managementGetEditionLeague(this.editionKey, this.leagueKey)
        },

        // Finally
        () => {
          this.loading = false
        }
      )
    },

    async updateLeague(payload) {
      this.savingData = true
      utils.tryAndCatch(
        this,
        async () => {
          this.league = await this.api.managementUpdateEditionLeague(this.editionKey, this.leagueKey, payload)
          this.$buefy.snackbar.open({
            message: 'Metadados atualizados com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000
          })

          // Get leagues to update dom
          this.getEdition()
        },
        () => {
          this.savingData = false
        }
      )
    },

    async activateGroupLeague() {
      this.savingData = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.managementActivateGroupLeague(this.editionKey)
          this.league = response.league

          if (response.errors?.length) {
            this.$buefy.dialog.alert({
              title: 'Equipas com erros',
              message: `Existem ${response.errors.length} equipas com erros que precisam ser corrigidas.`,
              type: 'is-warning'
            })
          }
          this.$buefy.snackbar.open({
            message: 'Ligas agrupadas com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000
          })

          // Get leagues to update dom
          this.getEdition()
        },
        () => {
          this.savingData = false
        }
      )
    },

    async updateEdition(payload) {
      this.savingData = true
      utils.tryAndCatch(
        this,
        async () => {
          this.league = await this.api.managementUpdateEdition(this.editionKey, payload)
          this.$buefy.snackbar.open({
            message: 'Metadados atualizados com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000
          })

          // Get leagues to update dom
          this.getEdition()
        },
        () => {
          this.savingData = false
        }
      )
    },

    // Utils
    goToView(view, keys) {
      const routeName =
        keys && keys.leagueKey
          ? 'manage-edition-league'
          : keys && keys.tournamentKey
          ? 'manage-edition-league-tournament'
          : 'manage-edition'
      this.$router.push({
        name: routeName,
        params: { view, ...keys }
      })
    },

    saveLocalLastEdition() {
      const edition = {
        name: this.edition.name,
        key: this.edition.key
      }
      localStorage.setItem('last-edition-open', JSON.stringify(edition))
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "edition": "Edição"
  },
  "en": {
    "edition": "Edition"
  }
}
</i18n>
